var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('fragment',[_c('v-container',[_c('v-row',{staticClass:"mt-4",attrs:{"align":"center","justify":"center"}},[_c('v-col',{attrs:{"sm":"8"}},[(!_vm.isOwner)?_c('div',{staticStyle:{"text-align":"center"}},[_c('h3',{staticClass:"pl-6"},[_c('span',{attrs:{"data-test":"notTheOwner-span"}},[_vm._v(" You're not the owner of this namespace. ")]),_c('p',{attrs:{"data-test":"namespaceOwnerMessage-p"}},[_vm._v(" Contact "+_vm._s(_vm.namespaceOwnerName())+" user for more information. ")])]),_c('br')]):_vm._e(),_c('div',{staticClass:"mt-6"},[_c('v-row',{staticClass:"mb-2"},[_c('v-col',{attrs:{"md":"auto"}},[_c('v-card',{attrs:{"tile":"","elevation":0}},[_vm._v(" Tenant ID: ")])],1),_c('v-col',{staticClass:"ml-auto",attrs:{"md":"auto"}},[_c('v-card',{staticClass:"auto",attrs:{"tile":"","elevation":0}},[_c('v-chip',[_c('span',{attrs:{"data-test":"tenant-span"}},[_vm._v(" "+_vm._s(_vm.tenant)+" ")]),_c('v-icon',{directives:[{name:"clipboard",rawName:"v-clipboard",value:(_vm.tenant),expression:"tenant"},{name:"clipboard",rawName:"v-clipboard:success",value:(function () {
                      _vm.$store.dispatch('snackbar/showSnackbarCopy', _vm.$copy.tenantId);
                    }),expression:"() => {\n                      $store.dispatch('snackbar/showSnackbarCopy', $copy.tenantId);\n                    }",arg:"success"}],attrs:{"right":""}},[_vm._v(" mdi-content-copy ")])],1)],1)],1)],1),_c('v-divider'),_c('v-divider')],1),(_vm.isOwner)?_c('div',{staticClass:"mt-6",attrs:{"data-test":"editOperation-div"}},[_c('ValidationObserver',{ref:"obs",scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var passes = ref.passes;
return [_c('v-row',[_c('v-col',[_c('h3',[_vm._v(" Namespace ")])]),_c('v-spacer'),_c('v-col',{staticClass:"ml-auto",attrs:{"md":"auto"}},[_c('v-btn',{attrs:{"outlined":""},on:{"click":function($event){return passes(_vm.editNamespace)}}},[_vm._v(" Rename Namespace ")])],1)],1),_c('div',{staticClass:"mt-4 mb-2"},[_c('ValidationProvider',{ref:"providerName",attrs:{"vid":"name","name":"Priority","rules":"required|rfc1123|noDot|namespace"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('v-text-field',{staticClass:"ml-3",attrs:{"label":"Name","error-messages":errors,"required":"","data-test":"name-text"},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}})]}}],null,true)})],1)]}}],null,false,1217819409)}),_c('v-divider'),_c('v-divider')],1):_vm._e(),(_vm.isOwner)?_c('div',{staticClass:"mt-6",attrs:{"data-test":"userOperation-div"}},[_c('v-row',[_c('v-col',[_c('h3',[_vm._v(" Members ")])]),_c('v-spacer'),(_vm.isEnterpriseOwner)?_c('div',{attrs:{"data-test":"newMember-div"}},[_c('v-col',{staticClass:"ml-auto",attrs:{"md":"auto"}},[_c('NamespaceNewMember',{attrs:{"ns-tenant":_vm.tenant}})],1)],1):_vm._e()],1),_c('div',{staticClass:"mt-5"},[_c('v-list',_vm._l((_vm.namespace.members),function(item){return _c('v-list-item',{key:item.id},[_c('v-row',[_c('v-col',{staticClass:"ml-auto",attrs:{"md":"auto"}},[_c('v-icon',[_vm._v(" mdi-account ")])],1),_c('v-col',[_c('v-list-item-title',{attrs:{"data-test":item.name+'-list'}},[_vm._v(" "+_vm._s(item.name)+" ")])],1),_c('v-spacer'),(_vm.isEnterpriseOwner)?_c('div',[_c('v-col',{staticClass:"ml-auto",attrs:{"md":"auto"}},[(item.id!==_vm.owner)?_c('v-btn',{attrs:{"outlined":"","data-test":"removeMember-btn"},on:{"click":function($event){return _vm.remove(item.name)}}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
return [_c('v-icon',_vm._g({},on),[_vm._v(" delete ")])]}}],null,true)},[_c('span',[_vm._v(" Remove user ")])])],1):_c('p',{staticClass:"mr-3",attrs:{"data-test":"owner-p"}},[_vm._v(" Owner ")])],1)],1):_c('div',{attrs:{"data-test":"role-div"}},[_c('v-col',{staticClass:"ml-auto",attrs:{"md":"auto"}},[_c('p',{attrs:{"data-test":"role-text"}},[_vm._v(" "+_vm._s(item.id === _vm.owner ? 'Owner' : 'Member')+" ")])])],1)],1)],1)}),1)],1),_c('v-divider'),_c('v-divider')],1):_vm._e(),(_vm.isEnterpriseOwner)?_c('div',{staticClass:"mt-6",attrs:{"data-test":"securityOperation-div"}},[_c('SettingSecurity',{attrs:{"has-tenant":_vm.hasTenant()}}),_c('v-divider'),_c('v-divider')],1):_vm._e(),(_vm.isOwner)?_c('div',{staticClass:"mt-6",staticStyle:{"display":"none"},attrs:{"data-test":"deleteOperation-div"}},[_c('h3',{staticClass:"mb-5"},[_vm._v(" Danger Zone ")]),_c('v-row',{staticClass:"mt-4 mb-2"},[_c('v-col',{staticClass:"ml-3"},[_c('h4',[_vm._v(" Delete this namespace ")]),_c('div',{staticClass:"ml-2"},[_c('p',[_vm._v(" After deleting a namespace, there is no going back. Be sure. ")])])]),_c('v-col',{staticClass:"ml-auto mb-4",attrs:{"md":"auto"}},[_c('NamespaceDelete',{attrs:{"ns-tenant":_vm.tenant}})],1)],1)],1):_vm._e()])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }